@use "../abstract/_colors"

.dropdown 
    float: left
    overflow: hidden

.drop
    margin-top: .1rem
    &__hidden
        display: none

.dropUp
    margin-top: .1rem
    &__hidden
        display: none

.drop--inline
    display: flex
    align-items: center
    cursor: pointer

.dropbtn 
    font-size: 1rem
    border: none
    outline: none
    color: white
    padding: 0 4px
    background-color: inherit
    font-family: inherit
    margin: 0
    cursor: pointer
    &__active
        font-size: 1.01rem
.dropbtn:hover
    font-size: 1.01rem

.dropdown-content
    flex-direction: column
    position: absolute
    top: 7vh
    right: 2%
    width:8%
    padding: .5% 1%
    border-radius: .4em
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px 
    background-color: colors.$primary-color
    z-index: 1

    &__active
        display: flex
        
    &__inactive
        display: none

    li 
        float: none
        font-size: .85rem
        font-family: Montserrat
        margin-bottom: .5em
        text-align: left
    a:hover
        font-size: .85rem
