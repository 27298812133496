@use "../components/elements"
@use "../abstract/colors"

.user--Widget
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: auto

    .user--operations--card
        @include elements.responsiveCard(40rem, 90vw, 90vw)
        padding: 1.5rem
        margin: 3rem
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center
        text-align: left

        .add--user--button--mobile
            display: none

        button
            @include elements.btn($color: colors.$primary-color )
            padding: .5em 1em
            border-radius: 5px
            font-size: .8rem
            width: auto
            margin:0 

        button:hover
            background-color: #a144b1

        input
            padding: .4em .8em
            border-radius: 5px
            border: solid .1em colors.$input--color
            width: 4rem
            font-size: .8rem
            text-align: center

        .add-sub--input--email
            width: 30%

        .add--substract
            width: 100%
            display: flex
            align-items: center
            justify-content: space-between

        .add--user
            width: 100%
            display: flex
            flex-direction: column
            justify-content: space-between

        #green
            background-color: #1D6F42
        
        #green:hover
            background-color: #387855
        
        p
            font-size: 1rem

        h1
            font-size: 1.4rem

        h2
            font-size: 1rem
            margin: 1.5rem 0 .5rem 0
    
        .userWidget--row
            display: flex
            justify-content: space-between
            align-items: center

        .userWidget--row--center
            display: flex
            justify-content: center
            align-items: center

        .add-excel--users
            display: flex
            justify-content: center
            align-items: center

        .add--user--button
            margin-top: .8rem

    .p3-container-2
        @include elements.responsiveCard(40rem, 90vw, 90vw)
        
        padding: 1.5rem
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center

        table
            width: 100%
            border-collapse: collapse
            padding: .7em .5em

        th, td
            border-bottom: 1px solid #ddd
            padding: .7em 0

        tr
            border: none
            text-align: left

        tr:hover
            background-color: #ececec

        td
            vertical-align: center
            text-align: left
            font-size: .87rem

        th
            font-size: 1rem
            font-weight: bold
            background-color: #fff
            color: #000

        h1
            font-size: 1.4rem
            margin-bottom: .7rem

        button
                @include elements.btn($color: colors.$primary-color )
                padding: .5em 1em
                border-radius: 5px
                font-size: .8rem
                margin: 0 0 0 .4em
                width: auto
        button:hover
            background-color: #a144b1

    .input--file
        display: none

    .excel--label
        @include elements.btn(#1D6F42)
        font-size: .8rem
        border-radius: 5px
        width: auto
        padding: .5em 1em    
    .excel--label:hover
        background-color: #387855

    .download--sample--button
        @include elements.btn($color: colors.$primary-color )
        padding: .5em 1em
        border-radius: 5px
        font-size: .8rem
        width: auto
        margin:0
        margin-left: .4em
        text-decoration: none 

    .download--sample--button:hover
        background-color: #a144b1

    #upload
        padding: .5em 1em
        border-radius: 5px
        font-size: .8rem
        background-color: #9B27AF
        color: #fff
        width: auto
        cursor: pointer
        margin-left: .4em

    #upload:hover
        background-color: #a144b1

    .alert
        width: 100%

@media only screen and (max-width: 1270px) and (orientation: portrait)
    .user--Widget
        .user--operations--card
            p
                font-size: 1.2rem

            h1
                font-size: 1.6rem

            h2
                font-size: 1.2rem
                margin: 2rem 0 .7rem 0

            button
                padding: .5em 1em
                font-size: 1rem

            button:hover
                background-color: #a144b1

            input
                padding: .4em .8em
                width: 5rem
                font-size: .9rem

            .add-sub--input--email
                width: 30%

            .add--user--button
                margin-top: 2rem
            
            #upload
                font-size: .9rem
            .download--sample--button
                font-size: .9rem
            .excel--label
                font-size: .9rem

        .p3-container-2
            td
                font-size: 1rem

            th
                font-size: 1.1rem
        
            button
                padding: .5em 1em
                font-size: .9rem
    

@media only screen and (max-width: 700px)
    .user--Widget
        .user--operations--card
            .add--substract
                flex-wrap: wrap
                justify-content: center

            p
                font-size: 1rem

            h1
                font-size: 1.4rem

            h2
                font-size: 1.1rem
                margin: 1.7rem 0 .7rem 0

            button
                padding: .5em .9em
                font-size: .9rem
                margin: 0 .2rem

            button:hover
                background-color: #a144b1

            input
                padding: .4em .8em
                width: 4rem
                font-size: .9rem
                margin: .5rem

            .add-sub--input--email
                width: auto
                margin: 0 0 0 .4em 
                width: 80%
                flex-grow: 10

            .add--user--button
                margin-top: 2rem
            
            .userWidget--row
                flex-wrap: wrap
                justify-content: center
            
            .userWidget--row--center
                display: none
            
            .add--user--button--mobile
                display: block

        .p3-container-2
            .hide--in--mobile
                display: none
            
            td
                font-size: .9rem