@use '../abstract/colors'
@use '../components/elements'
@use "../components/navBar"

.Page_2
    /* background-image: url("/public/car.jpg") */
    /*  background-color: colors.$primary-color  */
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    font-family: Montserrat


.Page2--container
    display: flex
    height: 90vh
    width: 100vw

.Page2--card
    margin: auto
    @include elements.card(30vw)
    display: flex
    padding: 1.3em

.card2--content
    display: flex
    flex-direction: column
    margin: auto
    width: 60%
    justify-content: center

//! Form

.Page2--Tittle
    padding: auto
    margin: 0 auto 1em auto
    font-size: 1.7rem
    color: colors.$title--gray

.username
    @include elements.inputs(colors.$input--color)
    height: 3em
    margin: 1.2em auto
    font-size: .8rem

.password
    @include elements.inputs(colors.$input--color)
    height: 3em
    margin: 1.2em auto
    font-size: .8rem

.login--btn
    @include elements.btn(colors.$dark--color)
    margin: 1em auto
    padding: 1em 1.5em
    font-size: 1rem

.form--label
    font-size: 1rem
    color: colors.$text--gray

.required
    font-size: .65rem
    color: colors.$primary-color

.forgotPassword
    margin: .8rem auto 0 auto
    font-size: 1rem
    color: colors.$text--gray
    text-decoration: none

.alertContainer
    @include elements.alertContainer
.alert
    @include elements.alert

@media only screen and (min-width: 1920px)
    .Page2--card
        width: 30vw
    .card2--content
        padding: 3vw 0

@media only screen and (max-width: 1270px) and (orientation: landscape)
    .Page2--card
        width: 40vw

@media only screen and (max-width: 1270px) and (orientation: portrait)
    .Page2--container
        height: 90vh
        
        .card2--content
            width: 60%

        .login--btn
            padding: 2vw 6vw

        .username
            font-size: .8rem
            padding: 1.5em

        .password  
            font-size: .8rem
            padding: 1.5em
    
        .Page2--card
            width: 60%
            padding: 2em 2em
        
        .Page2--Tittle
            font-size: 3.2vw

        .form--label
            font-size: 1.8vw

        .login--btn
            font-size: 1rem
            padding: 1rem

        .forgotPassword
            font-size: 1rem
        

@media only screen and (max-width: 700px)
    .Page2--container

        .Page2--card
            width: 90vw
            padding: 0
        
        .card2--content
            width: 80%
            padding: 1.5em 0

        .Page2--card
            width: 90vw
            padding: 0
        
        .login--btn
            padding: 2vw 6vw

        .username
            margin: 2vh 0
        
        .password
            margin: 2vh 0
    
        .Page2--Tittle
            font-size: 6vw

        .form--label
            font-size: 3.5vw
            margin: 1vh auto 0 auto

        .login--btn
            font-size: 2vw
            padding: 1.6vh 3vh
            font-size: 4vw
            border-radius: 1vh
            
        .forgotPassword
            font-size: 4vw
