@use '../components/_elements'
@use '../abstract/_colors'
@use '../components/modal'
@use "../components/navBar"
@use "../components/dropdown"

.page-three
    display: flex
    flex-direction: column
    

    .modal-component
        display: none

    .p3-nav-link
        margin-left: 1em

    .dashboard-3
        margin: auto
        display: flex
        flex-direction: column
        align-items: center

    .p3-container-1
        width: 60vw
        display: flex
        justify-content: space-between
        align-items: center
        padding: 2em 1em

        h1
            color: colors.$title--gray
            font-size: 1.5rem

        .p3-search
            display: flex
            flex-direction: row
            align-items: center
            justify-content: flex-end

            button
                @include elements.btn($color: colors.$primary-color )
                padding: .5em 1em
                border-radius: 5px
                font-size: .9rem
                margin: 0 0 0 .4em
                width: auto

            input
                padding: .4em 0
                border-radius: 5px
                border: solid .1em colors.$input--color
                margin: 0 0 0 .4em
                font-size: 1rem

            select
                padding: .35em .8em
                border-radius: 5px
                border: solid .1em colors.$input--color
                margin: 0 0 0 .4em
                font-size: 1rem

            p
                font-size: 1rem
                margin: 0 0 0 .4em

    .p3-container-2
        @include elements.responsiveCard (60vw , 80vw , 90vw )
        align-self: center
        .dashboard-3
            width: 100%

        table
            width: 100%
            text-align: center
            border-collapse: collapse
            padding: .7em .5em

        th, td
            border-bottom: 1px solid #ddd
            padding: .7em 0

        tr
            border: none

        tr:hover
            background-color: #ececec

        td
            vertical-align: center
            width: 33%

        th
            font-size: .9rem
            font-weight: bold
            background-color: #fff
            color: #000

        .report--button
            @include elements.btn($color: colors.$primary-color)
            padding: .6em .2em
            width: 7em
            font-size: .8rem

        .report--button:hover
            background: #ab52ba

        .time-stamps
            font-size: .8rem
            color: #858585
            margin-left: .1rem
            margin-top: .1em

        .danger-span
            display: flex
            flex-direction: column
            align-items: center
            font-size: .85rem

        .danger
            margin-right: .2rem

        #approved
            font-size: .85rem

        .danger--div
            display: flex
            align-items: center

        .plate
            letter-spacing: .2em
            font-size: .8rem

        .plate-square
            @include elements.btn(none)
            border: solid .15rem #191919
            border-radius: 4px
            color: black
            background-color: #efeeee
            padding: .6em .2em
            width: 7em
            font-size: .8rem

        .td--container
            width: 100%
            height: 100%
            display: flex
            align-items: center
            justify-content: center
    
    @media only screen and (max-width: 1270px) and (orientation: portrait)
        .p3-container-2
            padding: 1em

        .p3-container-1
            flex-direction: column
            padding-top: 0

            .p3-search
                flex-direction: row

            h1
                font-size: 1.5rem
                margin: 1em auto

            .p3-search

                button
                    font-size: 1rem
                    padding: .6em 1em

                input
                    font-size: 1rem
                    padding: .6em 0

                select
                    font-size: 1rem
                    padding: .5em .8em

                p
                    font-size: 1.2rem

    @media only screen and (max-width: 700px)
        .p3-container-1
            display: none
     
        .modal-component
            display: flex
            width: 80%
        
        .p3-container-2
            .plate-square
                padding: .6em .2em
                font-size: .8rem
            
            .report--button
                font-size: .75rem

            .time-stamps
                font-size: .7rem

            .danger-span
                font-size: .75rem

            #approved
                font-size: .75rem


@media only screen and (min-width: 1920px)
        .p3-container-1
            h1
                font-size: 1.8rem

        .p3-container-2
            table 
                td 
                    font-size: 1.6rem
                th
                    font-size: 1.6rem


