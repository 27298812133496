@use "../components/elements"
@use "../abstract/colors"
$font: Montserrat

.location--container
    display: flex
    align-items: center
    justify-content: center
    height: 90vh

.location--widget
    @include elements.responsiveCard(50vw, 90vw, 90vw)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 1.5em

    .options--container
        display: flex
        flex-direction: row

    .column
        display: flex
        flex-direction: column
        justify-content: space-between
        width: auto

    .row
        width: auto
        display: flex
        flex-direction: row
        align-items: left
        margin-bottom: 1rem

        .row--box
            margin: auto 1rem
            width: auto



    button
        @include elements.btn($color: colors.$primary-color )
        padding: .7em 1em
        border-radius: 5px
        font-size: .9rem
        margin: 1rem 0 0 .4em
        width: auto

    input
        padding: .4em .8em
        border-radius: 5px
        border: solid .1em colors.$input--color
        margin: 0 0 0 .4em
        width: 4rem
        font-size: .8rem

    select
        padding: .35em .8em
        border-radius: 5px
        border: solid .1em colors.$input--color
        margin: 0 0 0 .4em
        font-size: .8rem
    p
        font-size: 1rem
        margin: 0 0 0 .4em

    h2
        font-size: 1.3rem
        margin-bottom: 1rem

    h3
        font-size: 1rem
        margin: 1rem 0 .5rem 0
    
@media only screen and (min-width: 1920px)
    .location--widget
        width: 25%

@media only screen and (max-width: 1270px) and (orientation: portrait)
    .location--widget
        button
            padding: .7em 1em
            font-size: 1.1rem
   
        input
            font-size: 1.2rem
            padding: .4em .9em
            width: 4.5rem

        select
            font-size: 1.2rem
            padding: .4em .9em
       
        p
            font-size: 1.2rem
       
        h2
            font-size: 1.4rem
       
        h3
            font-size: 1.2rem
        

@media only screen and (max-width: 700px)

    .location--widget
        .options--container
            flex-direction: column

        .row
            margin-bottom: 0

        button
            padding: .7em 1em
            font-size: 1rem
   
        input
            font-size: 1rem
            padding: .4em .9em
            width: 4.5rem

        select
            font-size: 1rem
            padding: .4em .9em
       
        p
            font-size: 1.2rem
       
        h2
            font-size: 1.4rem
       
        h3
            font-size: 1.1rem

@media only screen and (max-height: 667px)
  
    .location--widget
       
        button
            padding: .7em 1em
            font-size: .85rem
   
        input
            font-size: .8rem
            padding: .4em .9em
            width: 4.5rem

        select
            font-size: .8rem
            padding: .4em .9em
       
        p
            font-size: .8rem
       
        h2
            font-size: 1rem
       
        h3
            font-size: .85rem
        
        label
             font-size: .8rem
