$font: Montserrat 

.generalForm--container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 90vh
    font: $font

.navigation 
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 2em
    font-size: .85rem
   
    .progress
        width: 30em
        padding: 0 1em
    .navigation--button
        border: none
        background: none
        cursor: pointer
        
@media only screen and (min-width: 1920px)
    .progress
        font-size: 1.25rem

@media only screen and (max-width: 1270px) and (orientation: portrait)
    .progress
        font-size: 1.15rem

@media only screen and (max-width: 700px)
    .navigation
        width: 85vw
        
