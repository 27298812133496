@use "../abstract/colors"

@mixin btn($color)
  padding: 10px 20px
  margin: 0 auto
  width: 55%
  background: $color
  border: none
  border-radius: .5em
  font-size: 1vw 
  color: #fff
  font-family: Montserrat
  cursor: pointer
  &__desactivated
        background-color: #9e9e9e
        pointer-events: none

@mixin inputs($input--color) 
    margin-bottom: 1em
    height: 40px
    width: 100%
    border: 1px solid $input--color
    border-radius: 5px 
    box-shadow: 1px 1px 2px #dad8d8

@mixin card ($width)
    margin: auto
    background-color: #fff
    width: $width
    border-radius: 15px
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px

    
@mixin responsiveCard ($desk, $tablet, $mobile )
    margin: auto
    background-color: #fff
    width: $desk
    border-radius: 15px
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px
    @media only screen and (max-width: 1270px) and (orientation: portrait)
        width: $tablet
    @media only screen and (max-width: 700px) and (orientation: portrait)
        width: $mobile

@mixin alertContainer()
    position: absolute
    top: 15vh
    left: 0
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    @media only screen and (max-width: 1270px) and (orientation: portrait)
        top: 25vh
    @media only screen and (max-width: 700px) and (orientation: portrait)
        top: 10vh

@mixin alert()
    border: none
    border-radius: .5rem
    display: flex
    align-items: center
    justify-content: center
    height: 7vh
    padding: 1em 2em
    color: #fff
    font-size: 1rem
    &__warning
        background-color: #e91e63
    &__success
        background-color: #00e676
    @media only screen and (max-width: 700px) 
        width: 100%
        border-radius: 0

.spacer 
    height: .5rem

.spacer--m 
    height: 2rem

.link 
    cursor: pointer
    font-size: 1rem
    color: colors.$dark--color



