@use "../abstract/colors"
@use "../components/elements"
@use '../components/navBar'
$font: Montserrat

.navBar
    background-color: colors.$primary-color
.navBarMobile
    background-color: colors.$primary-color

.form--splitter
    width: 100vw
    height: 100vh
    display: flex
    flex-direction: column
    font-family: $font

.splitter--container
    @include elements.responsiveCard(25%,60%, 90%)
    padding: 3em 1em
    display: flex
    align-items: center
    justify-content: center

    .splitter--form
        display: flex
        flex-direction: column
        align-content: center
        justify-content: center
        width: 70%

        h1
            color: colors.$title--gray
            font-size: 1.1rem
            margin: 1rem auto 1.2em

        input 
            @include elements.inputs(colors.$input--color)
            padding: .4rem
        .Link
            @include elements.btn(colors.$dark--color)
            display: flex
            justify-content: center
            padding: .8em
            font-size: .8rem
            margin: auto auto 1rem 
            width: 50%
        
            &__desactivated
                background-color: #9e9e9e
                pointer-events: none
            
        p
            font-size: .8rem
            text-align: center
            margin-bottom: .5rem


        a
            margin: auto
            text-decoration: none
            color: colors.$primary-color
            font-size: .8rem

.button--container
    display: flex
    align-items: center
    justify-content: center
    gap: .5rem

@media only screen and (max-width: 1270px) and (orientation: portrait) 
    
    .splitter--container
        padding: 4em 1em
        
        .splitter--form
            h1
                font-size: 1.8rem

            .Link
                padding: 1rem
                border-radius: .5rem
                font-size: .8rem
            
            input
                height: auto
                font-size: .8rem
                padding: 1em

@media only screen and (max-width: 700px) and (orientation: portrait)
    .splitter--container
        padding: 3em 1em
        
        .splitter--form

            width: 90%

            h1
                font-size: 1.1rem

            .Link
                padding: 1rem
                border-radius: .5rem
                font-size: 1rem
            
            input
                height: auto
                font-size: 1rem
                padding: .8em
                

@media only screen and (min-width: 2000px)

    .splitter--container
        width: 25%
        padding: 3em 1em
        
        .splitter--form
            h1
                font-size: 1.4vw

            .Link
                padding: 1.2rem
                border-radius: .5rem
                font-size: 1.2rem
            
            input
                height: auto
                font-size: 1rem
                padding: .8em
            
            a
                font-size: 1.3rem