@use '../abstract/_colors'

.pagination
    display: flex
    align-items: center
    justify-content: center
    padding: 1.5rem 0

.pagination-navigation
    background: none
    border: none

.pagination-numbers
    padding: .3rem
    background: none
    border: none
    border-radius: 5px
    font-size: 1rem
    margin: 0 .8rem
    cursor: pointer