@use '../components/_elements'
@use '../abstract/_colors'

.modal-base
    position: absolute
    left: 0
    top: 0
    width: 100vw
    height: 100vh
    background-color: #afafaf70
    backdrop-filter: blur(8px)
    z-index: 1
    
.modal-component
    margin: auto
    display: flex
    flex-direction: column
    justify-content: center

    .modal--button
        @include elements.btn(colors.$dark--color)
        width: 35%
        border-radius: .5em
        margin: 2em auto
        font-size: 1.1rem

.modal
    position: absolute
    top:20%
    left: 5%
    right: 5%
    @include elements.card(90%)
    display: flex
    flex-direction: column
    background-color: #ffffff
    z-index: 2


    .modal-content
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        padding: 2em 3em 

        h1
            font-size: 1.4rem
            margin-bottom: .8rem

        input
            @include elements.inputs(colors.$input--color)
            margin: .5em .5em .8em .5em
            font-size: 1rem
            padding: .8em .6rem
       
        select
            width: 100%
            margin: .5em
            padding: .5em .8em
            border: solid .1em colors.$input--color
            border-radius: 5px
            font-size: 1rem
            
        button    
            @include elements.btn($color: colors.$dark--color )
            margin: .5em
            font-size: .9rem
            padding: .6em 1em
        
        p
            margin: .5em
            font-size: 1.4rem
   
    .modal-footer
        display: flex
        justify-content: center
        
        button    
            @include elements.btn($color: colors.$dark--color )
            margin: .5em
            font-size: 1.2rem
            padding: .6em 1em
            


