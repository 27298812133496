@use "../components/elements"
@use "../abstract/colors"
$font: Montserrat

.success--card
    @include elements.responsiveCard(30em, 30rem, 90vw)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 3rem 5rem
    font-size: .8rem

    

    h1 
        margin: .6em 0

    button
        @include elements.btn(colors.$primary-color)




@media only screen and (min-width: 1920px)        
    .success--card
        font-size: 1.4rem
        h1  
            font-size: 1.6rem
        button
            font-size: 1rem

@media only screen and (max-width: 1270px) and (orientation: portrait)
    .success--card
        h1
            font-size: 1.5rem
        button
            font-size: 1rem
@media only screen and (max-width: 700px)
    .success--card
        button
            font-size: .7rem
