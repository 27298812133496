@use "../abstract/_colors"
@use "../components/_elements" as c

.form
    display: flex
    flex-direction: column
    margin: auto 12vw 
    
    p
        font-size: 1vw
        color: colors.$text--gray
        margin-bottom: 0.5vh 
    label 
        color: colors.$title--gray
        font-size: 1vw

    button
        @include c.btn(colors.$dark--color)
    
    .text--input
        @include c.inputs(colors.$input--color)
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button 
            -webkit-appearance: none
            margin: 0
        input[type=number] 
            -moz-appearance: textfield

    .validations
        font-size: .8rem
        margin: 0 auto 1.2em auto
        
    .required
        font-size: .7rem
        color: colors.$primary-color

.check--group 
    margin: 1em 0 1em 0
    display: flex
    align-items: center 
    justify-content: center
    width: auto 
    
    .check
        margin-right: .4em
        cursor: pointer

    .terms
        color: colors.$primary-color
        margin-left: 0.4vw
        cursor: pointer
        font-size: 1rem

#favcolor 
    border: 0
    width: 100%
    height: 40px
    border-radius: 15px


@media only screen and (max-width: 1270px)

    .form
        margin: auto
        background-color: #fff
        padding: 3em 2em
        width: 70vw
        border-radius: 15px
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px

        .text--input
            height: 60px

        p
            font-size: 2.6vw
            margin-bottom: 1.2vh

        label 
            font-size: 2.8vw
        
        button 
            padding: 20px 40px
            font-size: 2.5vw
        
        .terms
            margin-left: 0.3em
            font-size: 2.8vw
            
        .check
            width: 1.8vh
            height: 1.8vh

        .validations
            font-size: 1rem
            margin: 0 auto 1.4em auto
            
        .required
            font-size: .9rem
            color: colors.$primary-color
            

@media only screen and (max-width: 700px)

    .form
        width: 90vw
        padding: 2em 1em

        p
            font-size: 4vw

        label 
            font-size: 4.4vw
        
        button 
            padding: 10px 18px
            font-size: 4vw
        
        .terms
            margin-left: 1vw
            font-size: 4.4vw
            
        .text--input
            height: 35px

        .validations
            font-size: 1rem
            margin: 0 auto 1em auto
            
        .required
            font-size: .6rem
            color: colors.$primary-color

    @media only screen and (max-height: 600px) and (orientation: portrait)

        .form
            padding: 1.5em 1em
            .text--input
                height: 30px

            button
                padding: 10px 15px