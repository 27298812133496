@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

.spacer {
  height: 0.5rem;
}

.spacer--m {
  height: 2rem;
}

.link {
  cursor: pointer;
  font-size: 1rem;
  color: #6A0080;
}

.form {
  display: flex;
  flex-direction: column;
  margin: auto 12vw;
}
.form p {
  font-size: 1vw;
  color: #727272;
  margin-bottom: 0.5vh;
}
.form label {
  color: #4b4b4b;
  font-size: 1vw;
}
.form button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #6A0080;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
}
.form button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.form .text--input {
  margin-bottom: 1em;
  height: 40px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #dad8d8;
}
.form .text--input input::-webkit-outer-spin-button,
.form .text--input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form .text--input input[type=number] {
  -moz-appearance: textfield;
}
.form .validations {
  font-size: 0.8rem;
  margin: 0 auto 1.2em auto;
}
.form .required {
  font-size: 0.7rem;
  color: #9B27AF;
}

.check--group {
  margin: 1em 0 1em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.check--group .check {
  margin-right: 0.4em;
  cursor: pointer;
}
.check--group .terms {
  color: #9B27AF;
  margin-left: 0.4vw;
  cursor: pointer;
  font-size: 1rem;
}

#favcolor {
  border: 0;
  width: 100%;
  height: 40px;
  border-radius: 15px;
}

@media only screen and (max-width: 1270px) {
  .form {
    margin: auto;
    background-color: #fff;
    padding: 3em 2em;
    width: 70vw;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .form .text--input {
    height: 60px;
  }
  .form p {
    font-size: 2.6vw;
    margin-bottom: 1.2vh;
  }
  .form label {
    font-size: 2.8vw;
  }
  .form button {
    padding: 20px 40px;
    font-size: 2.5vw;
  }
  .form .terms {
    margin-left: 0.3em;
    font-size: 2.8vw;
  }
  .form .check {
    width: 1.8vh;
    height: 1.8vh;
  }
  .form .validations {
    font-size: 1rem;
    margin: 0 auto 1.4em auto;
  }
  .form .required {
    font-size: 0.9rem;
    color: #9B27AF;
  }
}
@media only screen and (max-width: 700px) {
  .form {
    width: 90vw;
    padding: 2em 1em;
  }
  .form p {
    font-size: 4vw;
  }
  .form label {
    font-size: 4.4vw;
  }
  .form button {
    padding: 10px 18px;
    font-size: 4vw;
  }
  .form .terms {
    margin-left: 1vw;
    font-size: 4.4vw;
  }
  .form .text--input {
    height: 35px;
  }
  .form .validations {
    font-size: 1rem;
    margin: 0 auto 1em auto;
  }
  .form .required {
    font-size: 0.6rem;
    color: #9B27AF;
  }
}
@media only screen and (max-width: 700px) and (max-height: 600px) and (orientation: portrait) {
  .form {
    padding: 1.5em 1em;
  }
  .form .text--input {
    height: 30px;
  }
  .form button {
    padding: 10px 15px;
  }
}
.absolute--navBar {
  background-color: #9B27AF;
  background-color: none;
  display: flex;
  flex-direction: columns;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  height: 10vh;
  width: 100%;
}

.absolute--navList {
  justify-content: space-between;
  list-style: none;
}
.absolute--navList li {
  display: inline-block;
  margin: 0 1.5vh 0 1.5vh;
}
.absolute--navList a {
  color: #fff;
  font-family: "Montserrat";
  font-weight: lighter;
  font-size: 0.8rem;
  letter-spacing: 2px;
  text-decoration: none;
}
.absolute--navList a:hover {
  font-size: 0.9rem;
  transition-duration: 0.2s;
}

.logo {
  display: none;
}

.login {
  display: block;
  position: absolute;
  top: 0;
  padding-top: 2vh;
  font-size: 1vw;
  color: #4b4b4b;
  text-decoration: none;
}

.absolute--navBarMobile {
  display: none;
}
.absolute--navBarMobile .mlogo {
  height: 4vh;
  position: absolute;
  left: 5%;
  top: 5.5%;
}

@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .absolute--navBar {
    display: none;
  }
  .absolute--navBarMobile {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2em;
    background-color: #9B27AF;
  }
  .absolute--navBarMobile ul {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 9vh;
    right: 0;
    width: 100%;
    padding: 2em 0 2em 0;
    border-top: solid 0.1rem #fff;
    background-color: #9B27AF;
  }
  .absolute--navBarMobile li {
    margin: 12px 0 12px 40px;
  }
  .absolute--navBarMobile a {
    font-size: 1.2rem;
    letter-spacing: 2px;
  }
  .absolute--navBarMobile a:hover {
    font-size: 1.6rem;
    transition-duration: 0.2s;
  }
}
@media only screen and (max-width: 700px) {
  .absolute--navBarMobile {
    padding: 0.5em;
  }
  .absolute--navBarMobile ul {
    padding: 1em;
    border-top: solid 0.05rem #fff;
    top: 8.8vh;
  }
  .absolute--navBarMobile li {
    text-align: center;
    margin: 1vh auto;
  }
  .absolute--navBarMobile a {
    font-size: 1rem;
  }
  .absolute--navBarMobile a:hover {
    font-size: 1.1rem;
    transition-duration: 0.2s;
  }
  .absolute--navBarMobile .hamburguer {
    padding: 0;
    margin: 0;
    height: 5.5vh;
  }
}
.Page--1 {
  font-family: Montserrat;
  display: flex;
}

.left {
  width: 50vw;
  padding: 0 40px;
  display: flex;
  align-items: center;
}
.left .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.left h1 {
  margin: auto;
  color: #4b4b4b;
  font-size: 1.3vw;
  margin-bottom: 3vh;
  letter-spacing: 2px;
}

.right {
  width: 50vw;
  height: 100vh;
  background-image: url("/public/car.jpg");
  background-color: #9B27AF;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.filter {
  width: 50vw;
  height: 100vh;
  background-color: rgba(155, 39, 175, 0.7529411765);
}

@media only screen and (max-width: 1270px) {
  .right {
    display: none;
  }
  .left {
    display: flex;
    width: 100vw;
    height: 90vh;
    padding: 0;
    background-color: #ede7f6;
  }
  .container {
    justify-content: center;
    align-items: center;
  }
  .container h1 {
    margin: 0 auto 1.2em auto;
    font-size: 2rem;
    color: #000000;
  }
}
@media only screen and (max-width: 700px) {
  .left {
    align-items: center;
    justify-content: center;
  }
  .left h1 {
    margin-top: 0;
    font-size: 1.3rem;
  }
  .container {
    margin: 0 auto;
  }
}
@media only screen and (max-height: 600px) and (orientation: portrait) {
  .left h1 {
    font-size: 1rem;
  }
}
.navBar {
  background-color: #9B27AF;
  display: flex;
  flex-direction: columns;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  height: 8vh;
  width: 100%;
}

.nav--locationNumber {
  list-style: none;
  color: #fff;
  font-family: "Montserrat";
  font-weight: lighter;
  font-size: 0.9rem;
  letter-spacing: 2px;
  text-decoration: none;
}

.nav--element {
  display: flex;
  width: 30%;
}

.nav--element--left {
  justify-content: flex-start;
}

.nav--element--right {
  justify-content: flex-end !important;
  gap: 1rem;
}

.nav--element--center {
  justify-content: center;
}

.nav--container-logo {
  display: flex;
  justify-content: flex-start;
  width: 33.33vw;
}

.nav--container-passes {
  display: flex;
  justify-content: center;
  width: 33.33vw;
  color: #fff;
}

.nav--container-menu {
  display: flex;
  justify-content: flex-end;
  width: 33.33vw;
}

.navList {
  justify-content: space-between;
  list-style: none;
}
.navList li {
  display: inline-block;
  margin: 0 1.5vh 0 1.5vh;
}
.navList a {
  color: #fff;
  font-family: "Montserrat";
  font-weight: lighter;
  font-size: 0.8rem;
  letter-spacing: 2px;
  text-decoration: none;
}
.navList a:hover {
  font-size: 0.9rem;
  transition-duration: 0.2s;
}
.navList .selected {
  border-bottom: 0.18em solid #fff;
}

.logo {
  display: none;
}

.login {
  display: block;
  position: absolute;
  top: 0;
  padding-top: 2vh;
  font-size: 1vw;
  color: #4b4b4b;
  text-decoration: none;
}

.navBarMobile {
  display: none;
}
.navBarMobile .mlogo {
  height: 4vh;
  position: absolute;
  left: 5%;
  top: 5.5%;
}

@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .navBar {
    display: none;
  }
  .navBarMobile {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2em;
    background-color: #9B27AF;
  }
  .navBarMobile .nav--container-passes {
    font-size: 1.1rem;
  }
  .navBarMobile ul {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 8vh;
    right: 0;
    left: 0;
    width: 100%;
    padding: 2em 0;
    border-top: solid 0.1rem #fff;
    background-color: #9B27AF;
  }
  .navBarMobile li {
    margin: 12px 0;
  }
  .navBarMobile a {
    font-size: 1.4rem;
    letter-spacing: 2px;
  }
  .navBarMobile a:hover {
    font-size: 1.6rem;
    transition-duration: 0.2s;
  }
}
@media only screen and (max-width: 700px) {
  .navBarMobile {
    padding: 0.5em;
  }
  .navBarMobile ul {
    padding: 1em;
    border-top: solid 0.05rem #fff;
    top: 8vh;
  }
  .navBarMobile li {
    text-align: center;
    margin: 1vh auto;
  }
  .navBarMobile a {
    font-size: 1rem;
  }
  .navBarMobile a:hover {
    font-size: 1.1rem;
    transition-duration: 0.2s;
  }
  .navBarMobile .hamburguer {
    padding: 0;
    margin: 0;
    height: 5.5vh;
  }
}
.Page_2 {
  /* background-image: url("/public/car.jpg") */
  /*  background-color: colors.$primary-color  */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: Montserrat;
}

.Page2--container {
  display: flex;
  height: 90vh;
  width: 100vw;
}

.Page2--card {
  margin: auto;
  margin: auto;
  background-color: #fff;
  width: 30vw;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  padding: 1.3em;
}

.card2--content {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 60%;
  justify-content: center;
}

.Page2--Tittle {
  padding: auto;
  margin: 0 auto 1em auto;
  font-size: 1.7rem;
  color: #4b4b4b;
}

.username {
  margin-bottom: 1em;
  height: 40px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #dad8d8;
  height: 3em;
  margin: 1.2em auto;
  font-size: 0.8rem;
}

.password {
  margin-bottom: 1em;
  height: 40px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #dad8d8;
  height: 3em;
  margin: 1.2em auto;
  font-size: 0.8rem;
}

.login--btn {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #6A0080;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  margin: 1em auto;
  padding: 1em 1.5em;
  font-size: 1rem;
}
.login--btn__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

.form--label {
  font-size: 1rem;
  color: #727272;
}

.required {
  font-size: 0.65rem;
  color: #9B27AF;
}

.forgotPassword {
  margin: 0.8rem auto 0 auto;
  font-size: 1rem;
  color: #727272;
  text-decoration: none;
}

.alertContainer {
  position: absolute;
  top: 15vh;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .alertContainer {
    top: 25vh;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .alertContainer {
    top: 10vh;
  }
}

.alert {
  border: none;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7vh;
  padding: 1em 2em;
  color: #fff;
  font-size: 1rem;
}
.alert__warning {
  background-color: #e91e63;
}
.alert__success {
  background-color: #00e676;
}
@media only screen and (max-width: 700px) {
  .alert {
    width: 100%;
    border-radius: 0;
  }
}

@media only screen and (min-width: 1920px) {
  .Page2--card {
    width: 30vw;
  }
  .card2--content {
    padding: 3vw 0;
  }
}
@media only screen and (max-width: 1270px) and (orientation: landscape) {
  .Page2--card {
    width: 40vw;
  }
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .Page2--container {
    height: 90vh;
  }
  .Page2--container .card2--content {
    width: 60%;
  }
  .Page2--container .login--btn {
    padding: 2vw 6vw;
  }
  .Page2--container .username {
    font-size: 0.8rem;
    padding: 1.5em;
  }
  .Page2--container .password {
    font-size: 0.8rem;
    padding: 1.5em;
  }
  .Page2--container .Page2--card {
    width: 60%;
    padding: 2em 2em;
  }
  .Page2--container .Page2--Tittle {
    font-size: 3.2vw;
  }
  .Page2--container .form--label {
    font-size: 1.8vw;
  }
  .Page2--container .login--btn {
    font-size: 1rem;
    padding: 1rem;
  }
  .Page2--container .forgotPassword {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 700px) {
  .Page2--container .Page2--card {
    width: 90vw;
    padding: 0;
  }
  .Page2--container .card2--content {
    width: 80%;
    padding: 1.5em 0;
  }
  .Page2--container .Page2--card {
    width: 90vw;
    padding: 0;
  }
  .Page2--container .login--btn {
    padding: 2vw 6vw;
  }
  .Page2--container .username {
    margin: 2vh 0;
  }
  .Page2--container .password {
    margin: 2vh 0;
  }
  .Page2--container .Page2--Tittle {
    font-size: 6vw;
  }
  .Page2--container .form--label {
    font-size: 3.5vw;
    margin: 1vh auto 0 auto;
  }
  .Page2--container .login--btn {
    font-size: 2vw;
    padding: 1.6vh 3vh;
    font-size: 4vw;
    border-radius: 1vh;
  }
  .Page2--container .forgotPassword {
    font-size: 4vw;
  }
}
.modal-base {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(175, 175, 175, 0.4392156863);
  backdrop-filter: blur(8px);
  z-index: 1;
}

.modal-component {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal-component .modal--button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #6A0080;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  width: 35%;
  border-radius: 0.5em;
  margin: 2em auto;
  font-size: 1.1rem;
}
.modal-component .modal--button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

.modal {
  position: absolute;
  top: 20%;
  left: 5%;
  right: 5%;
  margin: auto;
  background-color: #fff;
  width: 90%;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 2;
}
.modal .modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 3em;
}
.modal .modal-content h1 {
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
}
.modal .modal-content input {
  margin-bottom: 1em;
  height: 40px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #dad8d8;
  margin: 0.5em 0.5em 0.8em 0.5em;
  font-size: 1rem;
  padding: 0.8em 0.6rem;
}
.modal .modal-content select {
  width: 100%;
  margin: 0.5em;
  padding: 0.5em 0.8em;
  border: solid 0.1em #d3d3d3;
  border-radius: 5px;
  font-size: 1rem;
}
.modal .modal-content button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #6A0080;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  margin: 0.5em;
  font-size: 0.9rem;
  padding: 0.6em 1em;
}
.modal .modal-content button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.modal .modal-content p {
  margin: 0.5em;
  font-size: 1.4rem;
}
.modal .modal-footer {
  display: flex;
  justify-content: center;
}
.modal .modal-footer button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #6A0080;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  margin: 0.5em;
  font-size: 1.2rem;
  padding: 0.6em 1em;
}
.modal .modal-footer button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.drop {
  margin-top: 0.1rem;
}
.drop__hidden {
  display: none;
}

.dropUp {
  margin-top: 0.1rem;
}
.dropUp__hidden {
  display: none;
}

.drop--inline {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropbtn {
  font-size: 1rem;
  border: none;
  outline: none;
  color: white;
  padding: 0 4px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  cursor: pointer;
}
.dropbtn__active {
  font-size: 1.01rem;
}

.dropbtn:hover {
  font-size: 1.01rem;
}

.dropdown-content {
  flex-direction: column;
  position: absolute;
  top: 7vh;
  right: 2%;
  width: 8%;
  padding: 0.5% 1%;
  border-radius: 0.4em;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #9B27AF;
  z-index: 1;
}
.dropdown-content__active {
  display: flex;
}
.dropdown-content__inactive {
  display: none;
}
.dropdown-content li {
  float: none;
  font-size: 0.85rem;
  font-family: Montserrat;
  margin-bottom: 0.5em;
  text-align: left;
}
.dropdown-content a:hover {
  font-size: 0.85rem;
}

.page-three {
  display: flex;
  flex-direction: column;
}
.page-three .modal-component {
  display: none;
}
.page-three .p3-nav-link {
  margin-left: 1em;
}
.page-three .dashboard-3 {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-three .p3-container-1 {
  width: 60vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 1em;
}
.page-three .p3-container-1 h1 {
  color: #4b4b4b;
  font-size: 1.5rem;
}
.page-three .p3-container-1 .p3-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.page-three .p3-container-1 .p3-search button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: 0.9rem;
  margin: 0 0 0 0.4em;
  width: auto;
}
.page-three .p3-container-1 .p3-search button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.page-three .p3-container-1 .p3-search input {
  padding: 0.4em 0;
  border-radius: 5px;
  border: solid 0.1em #d3d3d3;
  margin: 0 0 0 0.4em;
  font-size: 1rem;
}
.page-three .p3-container-1 .p3-search select {
  padding: 0.35em 0.8em;
  border-radius: 5px;
  border: solid 0.1em #d3d3d3;
  margin: 0 0 0 0.4em;
  font-size: 1rem;
}
.page-three .p3-container-1 .p3-search p {
  font-size: 1rem;
  margin: 0 0 0 0.4em;
}
.page-three .p3-container-2 {
  margin: auto;
  background-color: #fff;
  width: 60vw;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-self: center;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .page-three .p3-container-2 {
    width: 80vw;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .page-three .p3-container-2 {
    width: 90vw;
  }
}
.page-three .p3-container-2 .dashboard-3 {
  width: 100%;
}
.page-three .p3-container-2 table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  padding: 0.7em 0.5em;
}
.page-three .p3-container-2 th, .page-three .p3-container-2 td {
  border-bottom: 1px solid #ddd;
  padding: 0.7em 0;
}
.page-three .p3-container-2 tr {
  border: none;
}
.page-three .p3-container-2 tr:hover {
  background-color: #ececec;
}
.page-three .p3-container-2 td {
  vertical-align: center;
  width: 33%;
}
.page-three .p3-container-2 th {
  font-size: 0.9rem;
  font-weight: bold;
  background-color: #fff;
  color: #000;
}
.page-three .p3-container-2 .report--button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  padding: 0.6em 0.2em;
  width: 7em;
  font-size: 0.8rem;
}
.page-three .p3-container-2 .report--button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.page-three .p3-container-2 .report--button:hover {
  background: #ab52ba;
}
.page-three .p3-container-2 .time-stamps {
  font-size: 0.8rem;
  color: #858585;
  margin-left: 0.1rem;
  margin-top: 0.1em;
}
.page-three .p3-container-2 .danger-span {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.85rem;
}
.page-three .p3-container-2 .danger {
  margin-right: 0.2rem;
}
.page-three .p3-container-2 #approved {
  font-size: 0.85rem;
}
.page-three .p3-container-2 .danger--div {
  display: flex;
  align-items: center;
}
.page-three .p3-container-2 .plate {
  letter-spacing: 0.2em;
  font-size: 0.8rem;
}
.page-three .p3-container-2 .plate-square {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: none;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  border: solid 0.15rem #191919;
  border-radius: 4px;
  color: black;
  background-color: #efeeee;
  padding: 0.6em 0.2em;
  width: 7em;
  font-size: 0.8rem;
}
.page-three .p3-container-2 .plate-square__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.page-three .p3-container-2 .td--container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .page-three .p3-container-2 {
    padding: 1em;
  }
  .page-three .p3-container-1 {
    flex-direction: column;
    padding-top: 0;
  }
  .page-three .p3-container-1 .p3-search {
    flex-direction: row;
  }
  .page-three .p3-container-1 h1 {
    font-size: 1.5rem;
    margin: 1em auto;
  }
  .page-three .p3-container-1 .p3-search button {
    font-size: 1rem;
    padding: 0.6em 1em;
  }
  .page-three .p3-container-1 .p3-search input {
    font-size: 1rem;
    padding: 0.6em 0;
  }
  .page-three .p3-container-1 .p3-search select {
    font-size: 1rem;
    padding: 0.5em 0.8em;
  }
  .page-three .p3-container-1 .p3-search p {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 700px) {
  .page-three .p3-container-1 {
    display: none;
  }
  .page-three .modal-component {
    display: flex;
    width: 80%;
  }
  .page-three .p3-container-2 .plate-square {
    padding: 0.6em 0.2em;
    font-size: 0.8rem;
  }
  .page-three .p3-container-2 .report--button {
    font-size: 0.75rem;
  }
  .page-three .p3-container-2 .time-stamps {
    font-size: 0.7rem;
  }
  .page-three .p3-container-2 .danger-span {
    font-size: 0.75rem;
  }
  .page-three .p3-container-2 #approved {
    font-size: 0.75rem;
  }
}

@media only screen and (min-width: 1920px) {
  .p3-container-1 h1 {
    font-size: 1.8rem;
  }
  .p3-container-2 table td {
    font-size: 1.6rem;
  }
  .p3-container-2 table th {
    font-size: 1.6rem;
  }
}
.modal-base {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(175, 175, 175, 0.4392156863);
  backdrop-filter: blur(8px);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-modal-component {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-modal-component .modal--button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #6A0080;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  width: 35%;
  border-radius: 0.5em;
  margin: 2em auto;
  font-size: 1.1rem;
}
.main-modal-component .modal--button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

.main-modal {
  margin: auto;
  background-color: #fff;
  width: 40vw;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 2;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .main-modal {
    width: 60vw;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .main-modal {
    width: 90vw;
  }
}
.main-modal .main-modal-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 3em;
}
.main-modal .main-modal-content h1 {
  font-size: 1.8rem;
  margin-top: 0.8em;
}
.main-modal .main-modal-content h2 {
  font-size: 1.4rem;
  margin-bottom: 1em;
}
.main-modal .main-modal-content input {
  margin-bottom: 1em;
  height: 40px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #dad8d8;
  margin: 0.5em 0.5em 0.8em 0.5em;
  font-size: 1rem;
  padding: 1.2em 0.8rem;
}
.main-modal .main-modal-content select {
  width: 100%;
  margin: 0.5em;
  padding: 0.5em 0.8em;
  border: solid 0.1em #d3d3d3;
  border-radius: 5px;
  font-size: 1.2rem;
}
.main-modal .main-modal-content p {
  font-size: 1rem;
}
.main-modal .modal-footer {
  display: flex;
  justify-content: center;
}
.main-modal .modal-footer button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #6A0080;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  margin: 0.4em;
  font-size: 1rem;
  padding: 0.6em 1em;
}
.main-modal .modal-footer button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

@media only screen and (max-width: 1270px) and (orientation: portrait) {
  h1 {
    font-size: 1.8rem;
    margin-top: 1em;
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1em;
  }
  p {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  h2 {
    font-size: 1rem;
    margin-bottom: 1em;
  }
  p {
    font-size: 1.2rem;
  }
  .modal-footer button {
    font-size: 0.8rem;
    padding: 0.4em 1em;
  }
  h1 {
    font-size: 1.5rem;
    margin-top: 1em;
  }
}
.navBar {
  background-color: #9B27AF;
}

.navBarMobile {
  background-color: #9B27AF;
}

.form--splitter {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
}

.splitter--container {
  margin: auto;
  background-color: #fff;
  width: 25%;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 3em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .splitter--container {
    width: 60%;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .splitter--container {
    width: 90%;
  }
}
.splitter--container .splitter--form {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 70%;
}
.splitter--container .splitter--form h1 {
  color: #4b4b4b;
  font-size: 1.1rem;
  margin: 1rem auto 1.2em;
}
.splitter--container .splitter--form input {
  margin-bottom: 1em;
  height: 40px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #dad8d8;
  padding: 0.4rem;
}
.splitter--container .splitter--form .Link {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #6A0080;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.8em;
  font-size: 0.8rem;
  margin: auto auto 1rem;
  width: 50%;
}
.splitter--container .splitter--form .Link__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.splitter--container .splitter--form .Link__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.splitter--container .splitter--form p {
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 0.5rem;
}
.splitter--container .splitter--form a {
  margin: auto;
  text-decoration: none;
  color: #9B27AF;
  font-size: 0.8rem;
}

.button--container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .splitter--container {
    padding: 4em 1em;
  }
  .splitter--container .splitter--form h1 {
    font-size: 1.8rem;
  }
  .splitter--container .splitter--form .Link {
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
  }
  .splitter--container .splitter--form input {
    height: auto;
    font-size: 0.8rem;
    padding: 1em;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .splitter--container {
    padding: 3em 1em;
  }
  .splitter--container .splitter--form {
    width: 90%;
  }
  .splitter--container .splitter--form h1 {
    font-size: 1.1rem;
  }
  .splitter--container .splitter--form .Link {
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 1rem;
  }
  .splitter--container .splitter--form input {
    height: auto;
    font-size: 1rem;
    padding: 0.8em;
  }
}
@media only screen and (min-width: 2000px) {
  .splitter--container {
    width: 25%;
    padding: 3em 1em;
  }
  .splitter--container .splitter--form h1 {
    font-size: 1.4vw;
  }
  .splitter--container .splitter--form .Link {
    padding: 1.2rem;
    border-radius: 0.5rem;
    font-size: 1.2rem;
  }
  .splitter--container .splitter--form input {
    height: auto;
    font-size: 1rem;
    padding: 0.8em;
  }
  .splitter--container .splitter--form a {
    font-size: 1.3rem;
  }
}
.location--card {
  margin: auto;
  background-color: #fff;
  width: 30em;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em 8em;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .location--card {
    width: 20em;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .location--card {
    width: 90vw;
  }
}
.location--card h1 {
  font-size: 1.2rem;
  margin-bottom: 1em;
}
.location--card input {
  margin-bottom: 1em;
  height: 40px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #dad8d8;
  font-size: 0.85rem;
  text-align: center;
  margin-bottom: 4em;
}
.location--card .Button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.8rem;
}
.location--card .Button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.location--card__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

.inputImg {
  display: none;
}

.img--label {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 0.8rem;
  border-radius: 5px;
  width: auto;
  padding: 0.5em 1em;
}
.img--label__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

.img--label:hover {
  background-color: #9B27AF;
}

@media only screen and (min-width: 1920px) {
  .location--card {
    font-size: 1.4rem;
  }
  .location--card h1 {
    font-size: 1.6rem;
  }
  .location--card .Button {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .location--card {
    font-size: 2rem;
    padding: 2.5em 5em;
  }
  .location--card h1 {
    font-size: 1.8rem;
  }
  .location--card .Button {
    font-size: 1.2rem;
  }
  .location--card input {
    font-size: 0.95rem;
  }
}
@media only screen and (max-width: 700px) {
  .location--card {
    font-size: 2rem;
    padding: 1.5em 2em;
  }
  .location--card h1 {
    font-size: 1.3rem;
  }
  .location--card .Button {
    font-size: 0.9rem;
  }
  .location--card input {
    font-size: 0.95rem;
  }
}
.street--card {
  margin: auto;
  background-color: #fff;
  width: 30em;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .street--card {
    width: 40rem;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .street--card {
    width: 90vw;
  }
}
.street--card h1 {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1em;
}
.street--card .input {
  margin-bottom: 1em;
  height: 40px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #dad8d8;
  display: flex;
  font-size: 0.85rem;
  text-align: center;
  resize: none;
  padding: 0.7em 0;
  margin-bottom: 4em;
}
.street--card .Button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 0.9rem;
}
.street--card .Button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.street--card__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

@media only screen and (min-width: 1920px) {
  .street--card {
    font-size: 0.9rem;
  }
  .street--card h1 {
    font-size: 1.1rem;
  }
  .street--card .Button {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .street--card {
    font-size: 1rem;
    padding: 2.5em 5em;
  }
  .street--card h1 {
    font-size: 1.8rem;
  }
  .street--card .Button {
    font-size: 1.2rem;
  }
  .street--card .input {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 700px) {
  .street--card {
    font-size: 1rem;
    padding: 1.5em 2em;
  }
  .street--card h1 {
    font-size: 1.3rem;
  }
  .street--card .Button {
    font-size: 0.9rem;
  }
  .street--card .input {
    font-size: 0.9rem;
  }
}
.house--card {
  margin: auto;
  background-color: #fff;
  width: 30em;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em 8em;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .house--card {
    width: 20em;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .house--card {
    width: 90vw;
  }
}
.house--card h1 {
  font-size: 1.2rem;
  margin-bottom: 1em;
}
.house--card .input {
  margin-bottom: 1em;
  height: 40px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #dad8d8;
  display: flex;
  font-size: 0.85rem;
  text-align: center;
  resize: none;
  padding: 0.7em 0;
  margin-bottom: 4em;
}
.house--card .Button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 0.9rem;
}
.house--card .Button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

@media only screen and (min-width: 1920px) {
  h1 {
    font-size: 1.2rem;
  }
  .Button {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .house--card {
    font-size: 2rem;
    padding: 2.5em 5em;
  }
  .house--card h1 {
    font-size: 1.8rem;
  }
  .house--card .Button {
    font-size: 1.2rem;
  }
  .house--card .input {
    font-size: 0.95rem;
  }
}
@media only screen and (max-width: 700px) {
  .house--card {
    font-size: 2rem;
    padding: 1.5em 2em;
  }
  .house--card h1 {
    font-size: 1.3rem;
  }
  .house--card .Button {
    font-size: 0.9rem;
  }
  .house--card .input {
    font-size: 0.95rem;
  }
}
.unit--container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  font: Montserrat;
}

.unit--card {
  margin: auto;
  background-color: #fff;
  width: 25em;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em 8em;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .unit--card {
    width: 20em;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .unit--card {
    width: 90vw;
  }
}
.unit--card h1 {
  font-size: 1.2rem;
  margin-bottom: 1em;
}
.unit--card .input {
  margin-bottom: 1em;
  height: 40px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #dad8d8;
  display: flex;
  font-size: 0.85rem;
  text-align: center;
  resize: none;
  padding: 0.7em 0;
  margin-bottom: 4em;
}
.unit--card .Button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 0.9rem;
}
.unit--card .Button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.unit--card .Button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

@media only screen and (min-width: 1920px) {
  .unit--card {
    font-size: 1.2rem;
  }
  .unit--card h1 {
    font-size: 1.2rem;
  }
  .unit--card .Button {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .unit--card {
    font-size: 2rem;
    padding: 2.5em 5em;
  }
  .unit--card h1 {
    font-size: 1.8rem;
  }
  .unit--card .Button {
    font-size: 1.2rem;
  }
  .unit--card .input {
    font-size: 0.95rem;
  }
}
@media only screen and (max-width: 700px) {
  .unit--card {
    font-size: 2rem;
    padding: 1.5em 2em;
  }
  .unit--card h1 {
    font-size: 1.3rem;
  }
  .unit--card .Button {
    font-size: 0.9rem;
  }
  .unit--card .input {
    font-size: 0.95rem;
  }
}
.info--card {
  margin: auto;
  background-color: #fff;
  width: 30em;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.3em 8em;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .info--card {
    width: 20em;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .info--card {
    width: 90vw;
  }
}
.info--card h1 {
  font-size: 1.4rem;
  margin-bottom: 1em;
}
.info--card .field {
  width: 100%;
}
.info--card p {
  text-align: left;
  margin-bottom: 0.5em;
  font-size: 0.9rem;
}
.info--card span {
  color: #9B27AF;
  font-size: 0.8rem;
}
.info--card .validations {
  font-size: 0.8rem;
  margin-bottom: 0.4em;
}
.info--card .terms {
  color: #9B27AF;
  cursor: pointer;
}
.info--card .info--input {
  margin-bottom: 1em;
  height: 40px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #dad8d8;
  display: flex;
  font-size: 0.8rem;
  text-align: center;
  resize: none;
  padding: 0.7em 0;
  margin-bottom: 1em;
}
.info--card .Button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 1em;
}
.info--card .Button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.info--card .Button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

@media only screen and (min-width: 1920px) {
  .info--card {
    font-size: 1rem;
  }
  .info--card h1 {
    font-size: 1.2rem;
  }
  .info--card .Button {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .info--card {
    font-size: 2rem;
    padding: 2.5em 5em;
  }
  .info--card h1 {
    font-size: 1.8rem;
  }
  .info--card .Button {
    font-size: 1.2rem;
  }
  .info--card .info--input {
    font-size: 0.95rem;
  }
}
@media only screen and (max-width: 700px) {
  .info--card {
    font-size: 1rem;
    padding: 1.5em 2em;
  }
  .info--card h1 {
    font-size: 1.3rem;
  }
  .info--card .Button {
    font-size: 0.9rem;
  }
  .info--card .info--input {
    font-size: 0.95rem;
  }
}
.generalForm--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  font: Montserrat;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;
  font-size: 0.85rem;
}
.navigation .progress {
  width: 30em;
  padding: 0 1em;
}
.navigation .navigation--button {
  border: none;
  background: none;
  cursor: pointer;
}

@media only screen and (min-width: 1920px) {
  .progress {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .progress {
    font-size: 1.15rem;
  }
}
@media only screen and (max-width: 700px) {
  .navigation {
    width: 85vw;
  }
}
.success--card {
  margin: auto;
  background-color: #fff;
  width: 30em;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 5rem;
  font-size: 0.8rem;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .success--card {
    width: 30rem;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .success--card {
    width: 90vw;
  }
}
.success--card h1 {
  margin: 0.6em 0;
}
.success--card button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
}
.success--card button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

@media only screen and (min-width: 1920px) {
  .success--card {
    font-size: 1.4rem;
  }
  .success--card h1 {
    font-size: 1.6rem;
  }
  .success--card button {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .success--card h1 {
    font-size: 1.5rem;
  }
  .success--card button {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 700px) {
  .success--card button {
    font-size: 0.7rem;
  }
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
}

.pagination-navigation {
  background: none;
  border: none;
}

.pagination-numbers {
  padding: 0.3rem;
  background: none;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  margin: 0 0.8rem;
  cursor: pointer;
}

.location--container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.location--widget {
  margin: auto;
  background-color: #fff;
  width: 50vw;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .location--widget {
    width: 90vw;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .location--widget {
    width: 90vw;
  }
}
.location--widget .options--container {
  display: flex;
  flex-direction: row;
}
.location--widget .column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
}
.location--widget .row {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: left;
  margin-bottom: 1rem;
}
.location--widget .row .row--box {
  margin: auto 1rem;
  width: auto;
}
.location--widget button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  padding: 0.7em 1em;
  border-radius: 5px;
  font-size: 0.9rem;
  margin: 1rem 0 0 0.4em;
  width: auto;
}
.location--widget button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.location--widget input {
  padding: 0.4em 0.8em;
  border-radius: 5px;
  border: solid 0.1em #d3d3d3;
  margin: 0 0 0 0.4em;
  width: 4rem;
  font-size: 0.8rem;
}
.location--widget select {
  padding: 0.35em 0.8em;
  border-radius: 5px;
  border: solid 0.1em #d3d3d3;
  margin: 0 0 0 0.4em;
  font-size: 0.8rem;
}
.location--widget p {
  font-size: 1rem;
  margin: 0 0 0 0.4em;
}
.location--widget h2 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
.location--widget h3 {
  font-size: 1rem;
  margin: 1rem 0 0.5rem 0;
}

@media only screen and (min-width: 1920px) {
  .location--widget {
    width: 25%;
  }
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .location--widget button {
    padding: 0.7em 1em;
    font-size: 1.1rem;
  }
  .location--widget input {
    font-size: 1.2rem;
    padding: 0.4em 0.9em;
    width: 4.5rem;
  }
  .location--widget select {
    font-size: 1.2rem;
    padding: 0.4em 0.9em;
  }
  .location--widget p {
    font-size: 1.2rem;
  }
  .location--widget h2 {
    font-size: 1.4rem;
  }
  .location--widget h3 {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 700px) {
  .location--widget .options--container {
    flex-direction: column;
  }
  .location--widget .row {
    margin-bottom: 0;
  }
  .location--widget button {
    padding: 0.7em 1em;
    font-size: 1rem;
  }
  .location--widget input {
    font-size: 1rem;
    padding: 0.4em 0.9em;
    width: 4.5rem;
  }
  .location--widget select {
    font-size: 1rem;
    padding: 0.4em 0.9em;
  }
  .location--widget p {
    font-size: 1.2rem;
  }
  .location--widget h2 {
    font-size: 1.4rem;
  }
  .location--widget h3 {
    font-size: 1.1rem;
  }
}
@media only screen and (max-height: 667px) {
  .location--widget button {
    padding: 0.7em 1em;
    font-size: 0.85rem;
  }
  .location--widget input {
    font-size: 0.8rem;
    padding: 0.4em 0.9em;
    width: 4.5rem;
  }
  .location--widget select {
    font-size: 0.8rem;
    padding: 0.4em 0.9em;
  }
  .location--widget p {
    font-size: 0.8rem;
  }
  .location--widget h2 {
    font-size: 1rem;
  }
  .location--widget h3 {
    font-size: 0.85rem;
  }
  .location--widget label {
    font-size: 0.8rem;
  }
}
.admin--report--button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 0.8rem;
  width: auto;
  padding: 0.5em;
  margin: auto 0.2em;
}
.admin--report--button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

.admin--report--button:hover {
  background-color: #9d45ac;
}

.admin--dissaprove--button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #6A0080;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 0.8rem;
  width: auto;
  padding: 0.5em;
  margin: auto 0.2em;
}
.admin--dissaprove--button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

.admin--dissaprove--button:hover {
  background-color: #77008f;
}

.alert {
  border-radius: 0;
}

.report--modal--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.report--modal--content #report--modal--title {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}
.report--modal--content input {
  width: 100%;
}
.report--modal--content button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #6A0080;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  margin: 0.5em;
  font-size: 0.9rem;
  padding: 0.6em 0.2em;
}
.report--modal--content button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.report--modal--content #report--modal--text {
  margin: 0.5em;
  font-size: 1rem;
}

.padding {
  padding: 0 5rem;
}

.animacion--abuelita {
  width: 50%;
  background: none;
}

@media only screen and (max-width: 700px) {
  .admin--report--button {
    font-size: 0.7rem;
    padding: 0.8em;
    margin-bottom: 0.3rem;
    width: 100%;
  }
  .admin--dissaprove--button {
    font-size: 0.7rem;
    padding: 0.7em;
    width: 100%;
  }
  .padding {
    padding: 0 1rem;
  }
}
.user--Widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
}
.user--Widget .user--operations--card {
  margin: auto;
  background-color: #fff;
  width: 40rem;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1.5rem;
  margin: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .user--Widget .user--operations--card {
    width: 90vw;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .user--Widget .user--operations--card {
    width: 90vw;
  }
}
.user--Widget .user--operations--card .add--user--button--mobile {
  display: none;
}
.user--Widget .user--operations--card button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: 0.8rem;
  width: auto;
  margin: 0;
}
.user--Widget .user--operations--card button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.user--Widget .user--operations--card button:hover {
  background-color: #a144b1;
}
.user--Widget .user--operations--card input {
  padding: 0.4em 0.8em;
  border-radius: 5px;
  border: solid 0.1em #d3d3d3;
  width: 4rem;
  font-size: 0.8rem;
  text-align: center;
}
.user--Widget .user--operations--card .add-sub--input--email {
  width: 30%;
}
.user--Widget .user--operations--card .add--substract {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user--Widget .user--operations--card .add--user {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.user--Widget .user--operations--card #green {
  background-color: #1D6F42;
}
.user--Widget .user--operations--card #green:hover {
  background-color: #387855;
}
.user--Widget .user--operations--card p {
  font-size: 1rem;
}
.user--Widget .user--operations--card h1 {
  font-size: 1.4rem;
}
.user--Widget .user--operations--card h2 {
  font-size: 1rem;
  margin: 1.5rem 0 0.5rem 0;
}
.user--Widget .user--operations--card .userWidget--row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user--Widget .user--operations--card .userWidget--row--center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user--Widget .user--operations--card .add-excel--users {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user--Widget .user--operations--card .add--user--button {
  margin-top: 0.8rem;
}
.user--Widget .p3-container-2 {
  margin: auto;
  background-color: #fff;
  width: 40rem;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .user--Widget .p3-container-2 {
    width: 90vw;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .user--Widget .p3-container-2 {
    width: 90vw;
  }
}
.user--Widget .p3-container-2 table {
  width: 100%;
  border-collapse: collapse;
  padding: 0.7em 0.5em;
}
.user--Widget .p3-container-2 th, .user--Widget .p3-container-2 td {
  border-bottom: 1px solid #ddd;
  padding: 0.7em 0;
}
.user--Widget .p3-container-2 tr {
  border: none;
  text-align: left;
}
.user--Widget .p3-container-2 tr:hover {
  background-color: #ececec;
}
.user--Widget .p3-container-2 td {
  vertical-align: center;
  text-align: left;
  font-size: 0.87rem;
}
.user--Widget .p3-container-2 th {
  font-size: 1rem;
  font-weight: bold;
  background-color: #fff;
  color: #000;
}
.user--Widget .p3-container-2 h1 {
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
}
.user--Widget .p3-container-2 button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: 0.8rem;
  margin: 0 0 0 0.4em;
  width: auto;
}
.user--Widget .p3-container-2 button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.user--Widget .p3-container-2 button:hover {
  background-color: #a144b1;
}
.user--Widget .input--file {
  display: none;
}
.user--Widget .excel--label {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #1D6F42;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 0.8rem;
  border-radius: 5px;
  width: auto;
  padding: 0.5em 1em;
}
.user--Widget .excel--label__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.user--Widget .excel--label:hover {
  background-color: #387855;
}
.user--Widget .download--sample--button {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: 0.8rem;
  width: auto;
  margin: 0;
  margin-left: 0.4em;
  text-decoration: none;
}
.user--Widget .download--sample--button__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}
.user--Widget .download--sample--button:hover {
  background-color: #a144b1;
}
.user--Widget #upload {
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: 0.8rem;
  background-color: #9B27AF;
  color: #fff;
  width: auto;
  cursor: pointer;
  margin-left: 0.4em;
}
.user--Widget #upload:hover {
  background-color: #a144b1;
}
.user--Widget .alert {
  width: 100%;
}

@media only screen and (max-width: 1270px) and (orientation: portrait) {
  .user--Widget .user--operations--card p {
    font-size: 1.2rem;
  }
  .user--Widget .user--operations--card h1 {
    font-size: 1.6rem;
  }
  .user--Widget .user--operations--card h2 {
    font-size: 1.2rem;
    margin: 2rem 0 0.7rem 0;
  }
  .user--Widget .user--operations--card button {
    padding: 0.5em 1em;
    font-size: 1rem;
  }
  .user--Widget .user--operations--card button:hover {
    background-color: #a144b1;
  }
  .user--Widget .user--operations--card input {
    padding: 0.4em 0.8em;
    width: 5rem;
    font-size: 0.9rem;
  }
  .user--Widget .user--operations--card .add-sub--input--email {
    width: 30%;
  }
  .user--Widget .user--operations--card .add--user--button {
    margin-top: 2rem;
  }
  .user--Widget .user--operations--card #upload {
    font-size: 0.9rem;
  }
  .user--Widget .user--operations--card .download--sample--button {
    font-size: 0.9rem;
  }
  .user--Widget .user--operations--card .excel--label {
    font-size: 0.9rem;
  }
  .user--Widget .p3-container-2 td {
    font-size: 1rem;
  }
  .user--Widget .p3-container-2 th {
    font-size: 1.1rem;
  }
  .user--Widget .p3-container-2 button {
    padding: 0.5em 1em;
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 700px) {
  .user--Widget .user--operations--card .add--substract {
    flex-wrap: wrap;
    justify-content: center;
  }
  .user--Widget .user--operations--card p {
    font-size: 1rem;
  }
  .user--Widget .user--operations--card h1 {
    font-size: 1.4rem;
  }
  .user--Widget .user--operations--card h2 {
    font-size: 1.1rem;
    margin: 1.7rem 0 0.7rem 0;
  }
  .user--Widget .user--operations--card button {
    padding: 0.5em 0.9em;
    font-size: 0.9rem;
    margin: 0 0.2rem;
  }
  .user--Widget .user--operations--card button:hover {
    background-color: #a144b1;
  }
  .user--Widget .user--operations--card input {
    padding: 0.4em 0.8em;
    width: 4rem;
    font-size: 0.9rem;
    margin: 0.5rem;
  }
  .user--Widget .user--operations--card .add-sub--input--email {
    width: auto;
    margin: 0 0 0 0.4em;
    width: 80%;
    flex-grow: 10;
  }
  .user--Widget .user--operations--card .add--user--button {
    margin-top: 2rem;
  }
  .user--Widget .user--operations--card .userWidget--row {
    flex-wrap: wrap;
    justify-content: center;
  }
  .user--Widget .user--operations--card .userWidget--row--center {
    display: none;
  }
  .user--Widget .user--operations--card .add--user--button--mobile {
    display: block;
  }
  .user--Widget .p3-container-2 .hide--in--mobile {
    display: none;
  }
  .user--Widget .p3-container-2 td {
    font-size: 0.9rem;
  }
}
.report--button--noRegister {
  padding: 10px 20px;
  margin: 0 auto;
  width: 55%;
  background: #9B27AF;
  border: none;
  border-radius: 0.5em;
  font-size: 1vw;
  color: #fff;
  font-family: Montserrat;
  cursor: pointer;
  width: 30%;
}
.report--button--noRegister__desactivated {
  background-color: #9e9e9e;
  pointer-events: none;
}

