@use '../abstract/_colors'

.navBar
    background-color: colors.$primary-color
    display: flex
    flex-direction: columns
    align-items: center
    justify-content: space-between
    padding: 0 40px
    height: 8vh
    width: 100%

.nav--locationNumber 
    list-style: none
    color: #fff
    font-family: "Montserrat"
    font-weight: lighter
    font-size: .9rem
    letter-spacing: 2px
    text-decoration: none

.nav--element 
    display: flex
    width: 30%

.nav--element--left
    justify-content: flex-start

.nav--element--right
    justify-content: flex-end !important
    gap: 1rem

.nav--element--center
    justify-content: center

.nav--container-logo
    display: flex
    justify-content: flex-start
    width: 33.33vw

.nav--container-passes
    display: flex
    justify-content: center
    width: 33.33vw
    color: #fff

.nav--container-menu
    display: flex
    justify-content: flex-end
    width: 33.33vw
    
.navList 
    justify-content: space-between
    list-style: none

    li 
        display: inline-block
        margin: 0 1.5vh 0 1.5vh

    a 
        color: #fff
        font-family: "Montserrat"
        font-weight: lighter
        font-size: .8rem
        letter-spacing: 2px
        text-decoration: none

    a:hover 
        font-size: .9rem
        transition-duration: 0.2s

    .selected
        border-bottom: .18em solid #fff

.logo
    display: none

.login
    display: block
    position: absolute
    top: 0
    padding-top: 2vh
    font-size: 1vw
    color: colors.$title--gray
    text-decoration: none
        
.navBarMobile 
    display: none
    .mlogo 
        height: 4vh
        position: absolute
        left: 5%
        top: 5.5%

@media only screen and (max-width: 1270px) and (orientation: portrait)
    .navBar 
        display: none
    
    .navBarMobile
        height: 10vh
        display: flex
        align-items: center
        justify-content: space-between
        padding: 2em
        background-color: colors.$primary-color

        .nav--container-passes
            font-size: 1.1rem
    
        ul 
            margin-top: 10px
            display: flex
            flex-direction: column
            align-items: center
            position: absolute
            top: 8vh
            right: 0
            left: 0
            width: 100%
            padding: 2em 0 
            border-top: solid .1rem #fff
            background-color: colors.$primary-color
    
        li 
            margin: 12px 0
    
        a  
            font-size: 1.4rem
            letter-spacing: 2px
        a:hover 
            font-size: 1.6rem
            transition-duration: 0.2s


@media only screen and (max-width: 700px)  
    .navBarMobile
        padding: .5em
        ul 
            padding: 1em  
            border-top: solid .05rem #fff
            top: 8vh
        
        li
            text-align: center
            margin: 1vh auto
        
        a 
            font-size: 1rem
            
        a:hover 
            font-size: 1.1rem
            transition-duration: 0.2s

        .hamburguer
            padding: 0 
            margin: 0
            height: 5.5vh