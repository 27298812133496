@use "../components/form"
@use "../components/elements"
@use '../components/absoluteNavBar'
@use "../abstract/colors"
$font: Montserrat

    
.location--card
    @include elements.responsiveCard(30em, 20em, 90vw )
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 3em 8em

    h1
        font-size: 1.2rem
        margin-bottom: 1em

    input
        @include elements.inputs(colors.$input--color)
        font-size: .85rem
        text-align: center
        margin-bottom: 4em
        
    .Button
        @include elements.btn(colors.$primary-color)
        font-size: .9rem
        padding: .8rem
    &__desactivated
        background-color: #9e9e9e
        pointer-events: none

.inputImg
    display: none
    
.img--label    
    @include elements.btn(colors.$primary-color)
    font-size: .8rem
    border-radius: 5px
    width: auto
    padding: .5em 1em    

.img--label:hover
    background-color: #9B27AF

    
@media only screen and (min-width: 1920px)
    .location--card
        font-size: 1.4rem
    
        h1  
            font-size: 1.6rem
        
        .Button
            font-size: 1rem


@media only screen and (max-width: 1270px) and (orientation: portrait)
     .location--card
        font-size: 2rem
        padding: 2.5em 5em
        h1  
            font-size: 1.8rem
        
        .Button
            font-size: 1.2rem

        input
            font-size: .95rem
   

@media only screen and (max-width: 700px)
   .location--card
        font-size: 2rem
        padding: 1.5em 2em
        h1  
            font-size: 1.3rem
        
        .Button
            font-size: .9rem

        input
            font-size: .95rem