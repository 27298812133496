@use "../components/form"
@use "../components/elements"
@use '../components/absoluteNavBar'
@use "../abstract/colors"
$font: Montserrat

.info--card
    @include elements.responsiveCard(30em, 20em, 90vw )
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 1.3em 8em

    h1
        font-size: 1.4rem
        margin-bottom: 1em
    
    .field
        width: 100%

    p
        text-align: left
        margin-bottom: .5em
        font-size: .9rem
    
    span 
        color: colors.$primary-color
        font-size: .8rem

    .validations
        font-size: .8rem
        margin-bottom: .4em
    
    .terms
        color: colors.$primary-color
        cursor: pointer

    .info--input
        @include elements.inputs(colors.$input--color)
        display: flex
        font-size: .8rem
        text-align: center
        resize: none
        padding: .7em 0
        margin-bottom: 1em
    .Button
        @include elements.btn(colors.$primary-color)
        font-size: .9rem
        margin-top: 1em
        
        &__desactivated
            background-color: #9e9e9e
            pointer-events: none
        

@media only screen and (min-width: 1920px)
    .info--card
        font-size: 1rem

        h1
            font-size: 1.2rem

        .Button
            font-size: 1rem

@media only screen and (max-width: 1270px) and (orientation: portrait)
    .info--card
        font-size: 2rem
        padding: 2.5em 5em
        h1
            font-size: 1.8rem

        .Button
            font-size: 1.2rem

        .info--input
            font-size: .95rem

@media only screen and (max-width: 700px)
    .info--card
        font-size: 1rem
        padding: 1.5em 2em
        h1
            font-size: 1.3rem

        .Button
            font-size: .9rem

        .info--input
            font-size: .95rem
