@use "../components/form"
@use "../components/elements"
@use '../components/absoluteNavBar'
@use "../abstract/colors"
$font: Montserrat

.street--card
    @include elements.responsiveCard(30em, 40rem, 90vw )
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 3em

    h1
        font-size: 1rem
        text-align: center
        margin-bottom: 1em

    .input
        @include elements.inputs(colors.$input--color)
        display: flex
        font-size: .85rem
        text-align: center
        resize: none
        padding: .7em 0
        margin-bottom: 4em

    .Button
        @include elements.btn(colors.$primary-color)
        font-size: .9rem
    &__desactivated
            background-color: #9e9e9e
            pointer-events: none


@media only screen and (min-width: 1920px)        
    .street--card
        font-size: .9rem
        h1
            font-size: 1.1rem

        .Button
            font-size: 1rem

@media only screen and (max-width: 1270px) and (orientation: portrait)
    .street--card
        font-size: 1rem
        padding: 2.5em 5em
        h1
            font-size: 1.8rem

        .Button
            font-size: 1.2rem

        .input
            font-size: .9rem

@media only screen and (max-width: 700px)
    .street--card
        font-size: 1rem
        padding: 1.5em 2em
        h1
            font-size: 1.3rem

        .Button
            font-size: .9rem

        .input
            font-size: .9rem
