@use '../components/_elements'
@use '../abstract/_colors'

.modal-base
    position: absolute
    left: 0
    top: 0
    width: 100vw
    height: 100vh
    background-color: #afafaf70
    backdrop-filter: blur(8px)
    z-index: 1
    display: flex
    align-items: center
    justify-content: center

    //! ESTE
.main-modal-component
    position: absolute
    left: 0
    top: 0
    width: 100vw
    height: 100vh
    margin: auto
    display: flex
    flex-direction: column
    justify-content: center

    .modal--button
        @include elements.btn(colors.$dark--color)
        width: 35%
        border-radius: .5em
        margin: 2em auto
        font-size: 1.1rem

.main-modal
    @include elements.responsiveCard(40vw , 60vw , 90vw )
    display: flex
    flex-direction: column
    background-color: #ffffff
    z-index: 2


    .main-modal-content
        text-align: center
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        padding: 2em 3em 

        h1
            font-size: 1.8rem
            margin-top: .8em

        h2
            font-size: 1.4rem
            margin-bottom: 1em

        input
            @include elements.inputs(colors.$input--color)
            margin: .5em .5em .8em .5em
            font-size: 1rem
            padding: 1.2em .8rem
       
        select
            width: 100%
            margin: .5em
            padding: .5em .8em
            border: solid .1em colors.$input--color
            border-radius: 5px
            font-size: 1.2rem
            
    
        p
            font-size: 1rem
   
    .modal-footer
        display: flex
        justify-content: center
        
        button    
            @include elements.btn($color: colors.$dark--color )
            margin: .4em
            font-size: 1rem
            padding: .6em 1em
            

@media only screen and (max-width: 1270px) and (orientation: portrait)
    h1
        font-size: 1.8rem
        margin-top: 1em
    h2
        font-size: 1.5rem
        margin-bottom: 1em

    p
        font-size: 1.4rem
@media only screen and (max-width: 700px) and (orientation: portrait)
    h2
        font-size: 1rem
        margin-bottom: 1em

    p
        font-size: 1.2rem
    
    .modal-footer
        button   
            font-size: .8rem
            padding: .4em 1em

    h1
        font-size: 1.5rem
        margin-top: 1em

