@use "../components/form"
@use "../components/elements"
@use '../components/absoluteNavBar'
@use "../abstract/colors"
$font: Montserrat

.Page--1
    font-family: $font
    display: flex

.left
    width: 50vw
    padding: 0 40px
    display: flex
    align-items: center 

    .container
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        width: 100%
    
    h1
        margin: auto
        color: colors.$title--gray
        font-size: 1.3vw  
        margin-bottom: 3vh
        letter-spacing: 2px 

.right
    width: 50vw
    height: 100vh
    background-image: url("/public/car.jpg")
    background-color: colors.$primary-color 
    background-position: center
    background-repeat: no-repeat
    background-size: cover

.filter
    width: 50vw
    height: 100vh
    background-color:#9b27afc0

//* Responsive design
@media only screen and (max-width: 1270px) 
   
    .right
        display: none

    .left
        display: flex
        width: 100vw
        height: 90vh
        padding: 0
        background-color: #ede7f6

    .container
        justify-content: center
        align-items: center

        h1
            margin: 0 auto 1.2em auto
            font-size: 2rem
            color: #000000
            

@media only screen and (max-width: 700px) 
   
    .left 
        align-items: center
        justify-content: center
        h1
            margin-top: 0
            font-size: 1.3rem

    .container
        margin: 0 auto


@media only screen and (max-height: 600px) and (orientation: portrait)

    .left
         h1
            font-size: 1rem
    

        




   