@use "../components/elements"
@use "../abstract/colors"

.admin--report--button
    @include elements.btn(colors.$primary-color)
    font-size: .8rem
    width: auto
    padding: .5em
    margin:  auto .2em 
    
.admin--report--button:hover
    background-color: #9d45ac
    
.admin--dissaprove--button
    @include elements.btn(colors.$dark--color)
    font-size: .8rem
    width: auto
    padding: .5em
    margin:  auto .2em 

.admin--dissaprove--button:hover
    background-color: #77008f

.alert
    border-radius: 0

.report--modal--content 
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    #report--modal--title
        font-size: 1.3rem
        margin-bottom: .5rem

    input
        width: 100%
        
        
    button    
        @include elements.btn($color: colors.$dark--color )
        margin: .5em
        font-size: .9rem
        padding: .6em .2em
    
    #report--modal--text
        margin: .5em
        font-size: 1rem

.padding
    padding: 0 5rem

.animacion--abuelita
    width: 50%
    background: none

@media only screen and (max-width: 700px)
    .admin--report--button
        font-size: .7rem
        padding: .8em
        margin-bottom: .3rem 
        width: 100%
    .admin--dissaprove--button
        font-size: .7rem
        padding: .7em
        width: 100%
    
    .padding
        padding: 0 1rem

